import { OnInit, Component } from "@angular/core";
import { AtomeService } from "./atome.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";


@Component({
    selector: 'app-pcareCosmetic',
    templateUrl: './personalCareAndCosmetics.Component.html',
    styleUrls: ['./personalCareAndCosmetics.Component.css']
})
export class PersonalCareAndCosmeticsComponent implements OnInit {

    styleAdditives: string = 'block';
    styleOralCare: string = 'none';
    styleHaireCare: string = 'none';
    stylesSkinCare: string = 'none';
    dairyclass: string;
    confectclass: string;
    meatclass: string;
    bakingclass: string;
    modelfade: string = 'modal fade';
    showfade: string;
    ProductName: string;
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    Industry: string;
    
    constructor(private _atomeService: AtomeService, private titleService: Title, private _activeRoute: ActivatedRoute) { }
    ngOnInit() {

        this.titleService.setTitle('ATOM-PersonalCareAndCosmetics');
        this.dairyclass = 'active';
        this.modelfade = 'modal fade';
        this.showfade = 'none';

        const prm: string = this._activeRoute.snapshot.params['prm'];

        if (prm === 'color-fine-additives') {
            this.showAdditives();
        } else if (prm === 'oral-care') {
            this.showOralCare();
        } else if (prm === 'hair-care') {
            this.showHairCare();
        } else if (prm === 'skin-care') {
            this.showSkinCare();
        }
    }

    openProductPostPopUp(value) {

        this.ProductName = value;
        this.modelfade = 'modal fade in';
        this.showfade = 'block';
    }

    closeClick() {

        this.modelfade = 'modal fade';
        this.showfade = 'none';
    }

    saveSegmentForm(value) {
      
        console.log(value);
        this._atomeService.saveSegmentInfo(value)
        .subscribe((res) => {
            if (res) {
                alert('Form sent successfully!');
                this.closeClick();
            } else {
                alert('Form sending failed!');
            }
        }, (error) => {
            
        })
    }

    showAdditives() {
        this.styleAdditives = 'block';
        this.styleOralCare = 'none';
        this.styleHaireCare = 'none';
        this.stylesSkinCare = 'none';
        this.dairyclass = 'active';
        this.confectclass = '';
        this.bakingclass = '';
        this.meatclass = '';
    }

    showOralCare() {
        this.styleAdditives = 'none';
        this.styleOralCare = 'block';
        this.styleHaireCare = 'none';
        this.stylesSkinCare = 'none';
        this.dairyclass = '';
        this.confectclass = 'active';
        this.meatclass = '';
        this.bakingclass = '';
    }

    showHairCare() {
        this.styleAdditives = 'none';
        this.styleOralCare = 'none';
        this.styleHaireCare = 'block';
        this.stylesSkinCare = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = 'active';
        this.bakingclass = '';
    }

    showSkinCare() {
        this.styleAdditives = 'none';
        this.styleOralCare = 'none';
        this.styleHaireCare = 'none';
        this.stylesSkinCare = 'block';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = 'active';
    }
}