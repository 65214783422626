import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';


import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AdminService {
    private baseUrl = environment.baseUrl;

    constructor(private _http: HttpClient) { }

    // get list of student
    getBlogList() {

        return this._http.get(this.baseUrl + '/GetBlogs')
    }
    getNewsList() {
       
        return this._http.get(this.baseUrl + '/GetNewsEvents')
    }
    deleteNews(id) {

        return this._http.get(this.baseUrl + '/deleteNews?id=' + id)
    }
    // get list of student
    getBlogById(id) {

        return this._http.get(this.baseUrl + '/getBlogById?id=' + id)
    }

    getNewsEventById(id) {

        return this._http.get(this.baseUrl + '/getNewsEventById?id=' + id)
    }

    saveNewBlog(blog, base64textString) {
        var obj = {
            'Title': blog.Title,
            'Discription': blog.Discription,
            'BlogImage': base64textString
        }

        var body = JSON.stringify(obj);
        return this._http.post(this.baseUrl + '/SetBlog', obj);
    }
    saveNewEvents(blog, base64textString) {
        var obj = {
            'Title': blog.Title,
            'Discription': blog.Discription,
            'BlogImage': base64textString
        }

        var body = JSON.stringify(obj);
        return this._http.post(this.baseUrl + '/setNewsEvents', obj);
    }
    saveNews(news) {

        var obj = {
            'comment': news.news
        }

        var body = JSON.stringify(obj);
        return this._http.post(this.baseUrl + '/setNews', obj);
    }

    adminLogin(login): Observable<any> {

        var obj = {
            'UserName': login.email,
            'Password': login.password
        }

        var body = JSON.stringify(obj);
        return this._http.post(this.baseUrl + '/AdminLogin', obj);
    }
}