import { OnInit, Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AtomeService } from "./atome.service";
import { Router, ActivatedRoute } from "@angular/router";

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    fooddiv: string;
    foodTop: string;
    foodopacity: number;
    pharmadiv: string;
    pharmaTop: string;
    pharmapacity: number;
    animaldiv: string;
    animalTop: string;
    animalpacity: number;
    Personaldiv: string;
    PersonalTop: string;
    Personalopacity: number;
    Constructiondiv: string;
    ConstructionTop: string;
    Constructionopacity: number;
    chemicalsdiv: string;
    chemicalsTop: string;
    chemicalsopacity: number;
    Paintdiv: string;
    PaintTop: string;
    Paintopacity: number;
    HomeCarediv: string;
    HomeCareTop: string;
    HomeCareopacity: number;
    headerOpecity: number;
    showimg: string;
    serachItem: string;
    imageObject: Array<object> = [{
        image: 'assets/Content/images/logos/paradise.jpg',
        thumbImage: 'assets/Content/images/logos/paradise.jpg',
        alt: 'paradise',
        href: 'https://www.paradisefruits.co.uk/',
        width: '180px',
        height: '90px'
    },{
        image: 'assets/Content/images/logos/pcc-logo.jpg',
        thumbImage: 'assets/Content/images/logos/pcc-logo.jpg',
        alt: 'pcc',
        href: 'https://www.pcc-exol.eu/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/prova-gb-logo.jpg',
        thumbImage: 'assets/Content/images/logos/prova-gb-logo.jpg',
        alt: 'prova',
        href: 'http://www.prova.fr/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/BASFo.jpg',
        thumbImage: 'assets/Content/images/logos/BASFo.jpg',
        alt: 'BASFo.jpg',
        href: 'https://nutrition.basf.com/global/en/human-nutrition.html',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/biospringerbaseQ.jpg',
        thumbImage: 'assets/Content/images/logos/biospringerbaseQ.jpg',
        alt: 'biospringerbaseQ',
        href: 'https://biospringer.com/en/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/brblogo.jpg',
        thumbImage: 'assets/Content/images/logos/brblogo.jpg',
        alt: 'brblogo',
        href: 'https://www.brb-international.com/lac/chemicals',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/capollogo.jpg',
        thumbImage: 'assets/Content/images/logos/capollogo.jpg',
        alt: 'capollogo',
        href: 'https://www.capol.de/en/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/ceamsalogo.jpg',
        thumbImage: 'assets/Content/images/logos/ceamsalogo.jpg',
        alt: 'ceamsalogo',
        href: 'https://www.ceamsa.com/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/globallogo.jpg',
        thumbImage: 'assets/Content/images/logos/globallogo.jpg',
        alt: 'globallogo',
        href: 'https://globaladsorbents.com/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/hexagonlogo.jpg',
        thumbImage: 'assets/Content/images/logos/hexagonlogo.jpg',
        alt: 'hexagonlogo',
        href: 'https://www.hexagonnutrition.com/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/mitsubhishilogo.jpg',
        thumbImage: 'assets/Content/images/logos/mitsubhishilogo.jpg',
        alt: 'mitsubhishilogo',
        href: 'https://www.mcls-ltd.com/en/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/palsgaardlogo.jpg',
        thumbImage: 'assets/Content/images/logos/palsgaardlogo.jpg',
        alt: 'palsgaardlogo',
        href: 'https://www.palsgaard.com/en',
        width: '180px',
        height: '90px'
    }
    ];

    constructor(private _atomeService: AtomeService, private titleService: Title, private _router: Router, private _activeRoute: ActivatedRoute) { }

    ngOnInit() {

        this.headerOpecity = 1;
        this.fooddiv = 'none';
        this.foodTop = '20%';
        this.foodopacity = 0;
        this.pharmadiv = 'none';
        this.pharmaTop = '20%';
        this.pharmapacity = 0;
        this.pharmadiv = 'none';
        this.animaldiv = '20%';
        this.animalpacity = 0;
        this.Personaldiv = 'none';
        this.PersonalTop = '20%';
        this.Personalopacity = 0;
        this.Constructiondiv = 'none';
        this.ConstructionTop = '20%';
        this.Constructionopacity = 0;
        this.chemicalsdiv = 'none';
        this.chemicalsTop = '20%';
        this.chemicalsopacity = 0;
        this.Paintdiv = 'none';
        this.PaintTop = '20%';
        this.Paintopacity = 0;
        this.HomeCarediv = 'none';
        this.HomeCareTop = '20%';
        this.HomeCareopacity = 0;


        this.titleService.setTitle('ATOM - Home');
        window.addEventListener('scroll', this.myFunction, true);
        $(".image").css({ 'transform': 'rotate(60deg)' });
        $(".image").css({ 'transform': 'rotate(-20deg)' });
        $('.box-inner').animate({ marginLeft: "0" }, 1500);
        $("#back-to-top, .back-to-top").click(function () { return $("html, body").animate({ scrollTop: 0 }, "800"), !1 })

        var $window = $(window);
        var windowHeight = $window.height();
        $('#carouselExampleFade').css({ height: $(window).innerHeight() });
        $('.thirdimg').css({ height: $(window).innerHeight() });

        if ($(window).innerHeight() > 900) {
            // $('.circleimage').css({ bottom: '-70px' });
        }
        $(window).resize(function () {
            $('#carouselExampleFade').css({ height: $(window).innerHeight() });
            $('.thirdimg').css({ height: $(window).innerHeight() });
            if ($(window).innerHeight() > 900) {
                // $('.circleimage').css({ bottom: '-70px' });
            }
        });
        // function to be called whenever the window is scrolled or resized

        $(window).scroll(function () {
            var pos = $window.scrollTop();
            $('.home-parallaxbg1').each(function () {

                var $element = $(this);
                var top = $element.offset().top;
                var height = $(window).height();

                // Check if totally above or totally below viewport
                if (top + height < pos || top > pos + windowHeight) {
                    return;
                }
                var xpos = "50%";
                $('.home-parallaxbg1').css('backgroundPosition', xpos + " " + Math.round(($('.home-parallaxbg1').offset().top - pos) * 0.5) + "px");
            });

            $('.home-parallaxbg2').each(function () {

                var $element = $(this);
                var top = $element.offset().top;
                var height = $(window).height();

                // Check if totally above or totally below viewport
                if (top + height < pos || top > pos + windowHeight) {
                    return;
                }
                var xpos = "50%";
                $('.home-parallaxbg2').css('backgroundPosition', xpos + " " + Math.round(($('.home-parallaxbg2').offset().top - pos) * 0.5) + "px");
            });

            $('.home-parallaxbg3').each(function () {

                var $element = $(this);
                var top = $element.offset().top;
                var height = $(window).height();

                // Check if totally above or totally below viewport
                if (top + height < pos || top > pos + windowHeight) {
                    return;
                }
                var xpos = "50%";
                $('.home-parallaxbg3').css('backgroundPosition', xpos + " " + Math.round(($('.home-parallaxbg3').offset().top - pos) * 0.5) + "px");
            });

            $('.home-parallaxbg4').each(function () {

                var $element = $(this);
                var top = $element.offset().top;
                var height = $(window).height();

                // Check if totally above or totally below viewport
                if (top + height < pos || top > pos + windowHeight) {
                    return;
                }
                var xpos = "50%";
                $('.home-parallaxbg4').css('backgroundPosition', xpos + " " + Math.round(($('.home-parallaxbg4').offset().top - pos) * 0.5) + "px");
            });
        });
    }

    searchItem(value) {
        this._router.navigate(['search', value.q]);
    }
    showImg(value) {

        this.headerOpecity = 0;
        var modal = document.getElementById('myModal');
        modal.style.display = "block";
        this.showimg = value;
    }
    checkClose(img) {
        if (img === 'img') {
            return false;
        }
    }
    closeImage(event) {
        if (event === undefined) {
            return false;
        }
        this.headerOpecity = 1;
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
        this.showimg = '';
    }
    myFunction() {
        if (window.pageYOffset >= document.getElementById("navbar").offsetTop) {

            document.getElementById("subnavbar").classList.add("sticky");
            document.getElementById("subnavbar").style.position = 'fixed';
            document.getElementById("setheader").style.display = 'none';
            document.getElementById("back-to-top").style.display = 'block';
            // document.getElementById("selectnav1").style.display = 'block';
        } else {

            document.getElementById("subnavbar").classList.remove("sticky");
            document.getElementById("subnavbar").style.position = '';
            document.getElementById("setheader").style.display = 'block';
            document.getElementById("back-to-top").style.display = 'none';
            // document.getElementById("selectnav1").style.display = 'none';
        }
    }

    contactUs(value) {

        this._atomeService.contactUs(value)
            .subscribe((res) => {
                if (res) {
                    alert('sent successfully!');
                    this.Name = '';
                    this.Email = '';
                    this.Phone = '';
                    this.Country = '';
                    this.Subject = '';
                    this.Message = '';
                } else {
                    alert('sending failed!');
                }
            }, (error) => {

            })
    }

    setNavigate(event) {

        this._router.navigate([event.target.value]);
    }
    ImageOut(value) {
        if (value === 'food') {
            this.fooddiv = 'none';
            this.foodTop = '20%';
            this.foodopacity = 0;
        }
        else if (value === 'pharma') {
            this.pharmadiv = 'none';
            this.pharmaTop = '20%';
            this.pharmapacity = 0;
        } else if (value === 'animal') {
            this.animaldiv = 'none';
            this.animalTop = '20%';
            this.animalpacity = 0;
        } else if (value === 'Personal') {
            this.Personaldiv = 'none';
            this.PersonalTop = '20%';
            this.Personalopacity = 0;
        } else if (value === 'Construction') {
            this.Constructiondiv = 'none';
            this.ConstructionTop = '20%';
            this.Constructionopacity = 0;
        } else if (value === 'chemicals') {
            this.chemicalsdiv = 'none';
            this.chemicalsTop = '20%';
            this.chemicalsopacity = 0;
        } else if (value === 'Paints') {
            this.Paintdiv = 'none';
            this.PaintTop = '20%';
            this.Paintopacity = 0;
        } else if (value === 'HomeCare') {
            this.HomeCarediv = 'none';
            this.HomeCareTop = '20%';
            this.HomeCareopacity = 0;
        }
    }
    ImageOver(value) {
        if (value === 'food') {
            this.fooddiv = 'block';
            this.foodTop = '40%';
            this.foodopacity = 1;
        } else if (value === 'pharma') {
            this.pharmadiv = 'block';
            this.foodTop = '40%';
            this.pharmapacity = 1;
        } else if (value === 'animal') {
            this.animaldiv = 'block';
            this.animalTop = '40%';
            this.animalpacity = 1;
        } else if (value === 'Personal') {
            this.Personaldiv = 'block';
            this.PersonalTop = '40%';
            this.Personalopacity = 1;
        } else if (value === 'Construction') {
            this.Constructiondiv = 'block';
            this.ConstructionTop = '40%';
            this.Constructionopacity = 1;
        } else if (value === 'chemicals') {
            this.chemicalsdiv = 'block';
            this.chemicalsTop = '40%';
            this.chemicalsopacity = 1;
        } else if (value === 'Paints') {
            this.Paintdiv = 'block';
            this.PaintTop = '40%';
            this.Paintopacity = 1;
        } else if (value === 'HomeCare') {
            this.HomeCarediv = 'block';
            this.HomeCareTop = '40%';
            this.HomeCareopacity = 1;
        }
    }
    fbook() {
        window.open('https://www.facebook.com/ATOM-282559109240788/?modal=admin_todo_tour/');
    }
    Instagram() {
        window.open('https://www.instagram.com/atomfzco/?hl=en/');
    }
    twitter() {
        window.open('https://twitter.com/Atom24291290');
    }
    linkedin() {
        window.open('https://www.linkedin.com/company/atom-fzco');
    }
}