import { OnInit, Component } from "@angular/core";
import { AtomeService } from "./atome.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { element } from "protractor";

@Component({
    selector: 'app-pharma',
    templateUrl: './pharmaCeuticals.component.html',
    styleUrls: ['./pharmaCeuticals.Component.css']
})
export class PharmaCeuticalsComponent implements OnInit {

    styleAntiInfective: string = 'block';
    styleDiabetes: string = 'none';
    styleAntiFlam: string = 'none';
    styleRespiratory: string = 'none';
    styleCardiovas: string = 'none';
    styleMultiVitamin: string = 'none';
    styleGastro: string = 'none';
    styleExcipients: string = 'none';
    dairyclass: string;
    confectclass: string;
    meatclass: string;
    bakingclass: string;
    juiceclass: string;
    oilclass: string;
    savouryclass: string;
    healthclass: string;
    modelfade: string = 'modal fade';
    showfade: string;
    ProductName: string;
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    Industry: string;

    constructor(private _atomeService: AtomeService, private titleService: Title, private _activeRoute: ActivatedRoute) { }
    ngOnInit() {

        this.titleService.setTitle('ATOM-Pharmaceuticals');
        this.dairyclass = 'active';
        this.modelfade = 'modal fade';
        this.showfade = 'none';

        const prm: string = this._activeRoute.snapshot.params['prm'];

        if (prm === 'multivitamins-dermatology') {
            this.showMultivi();
        } else if (prm === 'gastrointestinal') {
            this.showGastro();
        } else if (prm === 'diabetes-management') {
            this.shiwDiabManag();
        } else if (prm === 'anti-inflammatory-analgesic') {
            this.shoAntiInfect();
        } else if (prm === 'respiratory') {
            this.showRepository();
        } else if (prm === 'cardiovascular') {
            this.ShowCardio();
        } else if (prm === 'excipients') {
            this.showExciption();
        } else {
            this.shoAntiInfect();
        }
    }

    openProductPostPopUp(value) {

        this.ProductName = value;
        this.modelfade = 'modal fade in';
        this.showfade = 'block';
    }

    closeClick() {

        this.modelfade = 'modal fade';
        this.showfade = 'none';
    }

    saveSegmentForm(value) {

        console.log(value);
        this._atomeService.saveSegmentInfo(value)
            .subscribe((res) => {
                if (res) {
                    alert('Form sent successfully!');
                    this.closeClick();
                } else {
                    alert('Form sending failed!');
                }
            }, (error) => {

            })
    }
    shoAntiInfect() {
        this.styleAntiInfective = 'block';
        this.styleDiabetes = 'none';
        this.styleAntiFlam = 'none';
        this.styleRespiratory = 'none';
        this.styleCardiovas = 'none';
        this.styleMultiVitamin = 'none';
        this.styleGastro = 'none';
        this.styleExcipients = 'none';
        this.dairyclass = 'active';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    shiwDiabManag() {
        this.styleAntiInfective = 'none';
        this.styleDiabetes = 'block';
        this.styleAntiFlam = 'none';
        this.styleRespiratory = 'none';
        this.styleCardiovas = 'none';
        this.styleMultiVitamin = 'none';
        this.styleGastro = 'none';
        this.styleExcipients = 'none';
        this.dairyclass = '';
        this.confectclass = 'active';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showAntiInflam() {
        this.styleAntiInfective = 'none';
        this.styleDiabetes = 'none';
        this.styleAntiFlam = 'block';
        this.styleRespiratory = 'none';
        this.styleCardiovas = 'none';
        this.styleMultiVitamin = 'none';
        this.styleGastro = 'none';
        this.styleExcipients = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = 'active';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showRepository() {
        this.styleAntiInfective = 'none';
        this.styleDiabetes = 'none';
        this.styleAntiFlam = 'none';
        this.styleRespiratory = 'block';
        this.styleCardiovas = 'none';
        this.styleMultiVitamin = 'none';
        this.styleGastro = 'none';
        this.styleExcipients = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = 'active';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    ShowCardio() {
        this.styleAntiInfective = 'none';
        this.styleDiabetes = 'none';
        this.styleAntiFlam = 'none';
        this.styleRespiratory = 'none';
        this.styleCardiovas = 'block';
        this.styleMultiVitamin = 'none';
        this.styleGastro = 'none';
        this.styleExcipients = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = 'active';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showMultivi() {
        this.styleAntiInfective = 'none';
        this.styleDiabetes = 'none';
        this.styleAntiFlam = 'none';
        this.styleRespiratory = 'none';
        this.styleCardiovas = 'none';
        this.styleMultiVitamin = 'block';
        this.styleGastro = 'none';
        this.styleExcipients = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = 'active';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showGastro() {
        this.styleAntiInfective = 'none';
        this.styleDiabetes = 'none';
        this.styleAntiFlam = 'none';
        this.styleRespiratory = 'none';
        this.styleCardiovas = 'none';
        this.styleMultiVitamin = 'none';
        this.styleGastro = 'block';
        this.styleExcipients = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = 'active';
        this.healthclass = '';
    }

    showExciption() {
        this.styleAntiInfective = 'none';
        this.styleDiabetes = 'none';
        this.styleAntiFlam = 'none';
        this.styleRespiratory = 'none';
        this.styleCardiovas = 'none';
        this.styleMultiVitamin = 'none';
        this.styleGastro = 'none';
        this.styleExcipients = 'block';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = 'active';
    }
}