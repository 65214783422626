import { OnInit, Component } from "@angular/core";
import { AdminService } from "./admin.service";
import { Router } from '@angular/router';
import { FacebookService, UIParams, UIResponse } from 'ngx-facebook';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-addnews',
    templateUrl: './addNews.component.html',
    styleUrls: ['./addBlogs.component.css'],
    providers: [AdminService]
})
export class AddNewsComponent implements OnInit {
    news: string;
    firstName: string;
    laststName: string;
    userName: string;
    constructor(private _blogService: AdminService, private _router: Router, private titleService: Title) { }
    ngOnInit() {

        this.titleService.setTitle('ATOM-Add News');
        if (sessionStorage.getItem('username') === null) {
            this._router.navigate(['/adminLogin']);
        } else {
            this.firstName = sessionStorage.getItem('firstName');
            this.laststName = sessionStorage.getItem('lastName');
        }
    }

    // share(url: string) {

    //     let params: UIParams = {
    //       href: 'https://github.com/zyra/ngx-facebook',
    //       method: 'share'
    //     };

    //     this.fb.ui(params)
    //       .then((res: UIResponse) => console.log(res))
    //       .catch((e: any) => console.error(e));

    //   }
    saveNews(news) {
        this._blogService.saveNews(news)
            .subscribe((res) => {
                if (res != null || res != undefined) {
                    if (res) {
                        debugger;
                        window.open('https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=819f6eu1an6zvf&redirect_uri=http://api.atomchemie.com/api/Admin/LinkedINAuth&state=987654321&scope=r_basicprofile,r_emailaddress,rw_company_admin,w_share')
                        this.news = '';
                        alert('succefully create added news');

                    } else {
                        alert('some error please try agian');
                    }
                }
            }, (error) => {
                alert(error);
            })
    }
    LogOut() {
        sessionStorage.removeItem('username');
        this._router.ngOnDestroy();
        this._router.navigate(['/adminLogin']);
    }
}
