import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { FoodDairyComponent } from './segments/foodDairy.Component';
import { HeaderComponent } from './shared/header.component';
import { PharmaCeuticalsComponent } from './segments/pharmaCeuticals.component';
import { FooterComponent } from './shared/footer.component';
import { AnimalFeedComponent } from './segments/animalFeed.Component';
import { PersonalCareAndCosmeticsComponent } from './segments/personalCareAndCosmetics.component';
import { ConstructionComponent } from './segments/construction.component';
import { PerformanceChemicalsComponent } from './segments/performanceChemicals.component';
import { PaintsCoatingsInksComponent } from './segments/paintsCoatingsInks.component';
import { HomeCareAndIndustrialComponent } from './segments/homeCareAndIndustrial.component';
import { AboutUsComponent } from './segments/aboutUs.component';
import { CareerComponent } from './segments/career.component';
import { ContactUsComponent } from './segments/contactUs.component';
import { HomeComponent } from './segments/home.component';
import { AdminLoginComponent } from './Admin/adminLogin.component';
import { MHeaderComponent } from './master/mheader.component';
import { MFooterComponent } from './master/footer.component';
import { AddBlogComponent } from './Admin/addBlogs.component';
import { AddNewsComponent } from './Admin/addNews.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BlogListComponent } from './Admin/blogList.component';
import { FileSelectDirective } from 'ng2-file-upload';
import { BlogComponent } from './Admin/blog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SliderModule } from 'angular-image-slider';
import { FacebookModule } from 'ngx-facebook';
import { AtomeService } from './segments/atome.service';
import { PrivacyComponent } from './Admin/privacy.component';
import { TermsComponent } from './Admin/term.component';
import { SearchComponent } from './segments/search.component';
import { NewsComponent } from './Admin/news.component';
import { NewsDetailsComponent } from './Admin/newsdetails.component';
import { AddNewsEventsComponent } from './Admin/addNewsEvents.component';
import { LinkedInShareComponent } from './shared/linkedIn.component';
import { FbLikeComponent } from './shared/facebookshare.component';
import { SliderComponent } from './shared/slider.component';
import { AgmCoreModule } from '@agm/core';
import { NewsEventListComponent } from './Admin/newsEventList.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgImageSliderModule } from 'ng-image-slider';

const appRoutes: Routes = [

  {path: 'foodDairy', component: FoodDairyComponent},
  {path: 'foodDairy/:prm', component: FoodDairyComponent},
  {path: 'pharmaCeuticals', component: PharmaCeuticalsComponent},
  {path: 'pharmaCeuticals/:prm', component: PharmaCeuticalsComponent},
  {path: 'animalFeed', component: AnimalFeedComponent},
  {path: 'animalFeed/:prm', component: AnimalFeedComponent},
  {path: 'personalCareAndCosmetics', component: PersonalCareAndCosmeticsComponent},
  {path: 'personalCareAndCosmetics/:prm', component: PersonalCareAndCosmeticsComponent},
  {path: 'construction', component: ConstructionComponent},
  {path: 'construction/:prm', component: ConstructionComponent},
  {path: 'performanceChemicals', component: PerformanceChemicalsComponent},
  {path: 'performanceChemicals/:prm', component: PerformanceChemicalsComponent},
  {path: 'paintsCoatingsInks', component: PaintsCoatingsInksComponent},
  {path: 'paintsCoatingsInks/:prm', component: PaintsCoatingsInksComponent},
  {path: 'homeCareAndIndustrial', component: HomeCareAndIndustrialComponent},
  {path: 'homeCareAndIndustrial/:prm', component: HomeCareAndIndustrialComponent},
  {path: 'aboutUs', component: AboutUsComponent},
  {path: 'career', component: CareerComponent},
  {path: 'contactUs', component: ContactUsComponent},
  {path: 'home', component: HomeComponent},
  {path: 'adminLogin', component: AdminLoginComponent},
  {path: 'addNews', component: AddNewsComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'term', component: TermsComponent},
  {path: 'search/:q', component: SearchComponent},
  {path: 'news', component: NewsComponent},
  {path: 'newsEventList', component: NewsEventListComponent},
  {path: 'newsdetails/:id', component: NewsDetailsComponent},
  {path: 'addNewsEvents', component: AddNewsEventsComponent},
  {path: 'slider', component: SliderComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
]
@NgModule({
  declarations: [
    AppComponent, FoodDairyComponent, HeaderComponent, FooterComponent, PharmaCeuticalsComponent,
    AnimalFeedComponent, PersonalCareAndCosmeticsComponent, ConstructionComponent, PerformanceChemicalsComponent,
    PaintsCoatingsInksComponent, HomeCareAndIndustrialComponent, AboutUsComponent,
    CareerComponent, ContactUsComponent, HomeComponent, AdminLoginComponent, MHeaderComponent, MFooterComponent,
    AddNewsComponent, PrivacyComponent, TermsComponent, SearchComponent, AddBlogComponent, BlogComponent, BlogListComponent,
    NewsComponent, NewsDetailsComponent, AddNewsEventsComponent, LinkedInShareComponent, FbLikeComponent,
    SliderComponent, NewsEventListComponent
  ],
  imports: [
    BrowserModule, FormsModule,NgImageSliderModule, HttpClientModule, RouterModule.forRoot(appRoutes, {useHash: true}),
    SliderModule, BrowserAnimationsModule, FacebookModule.forRoot(), NgxPaginationModule
  ],
  providers: [AtomeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
