import { OnInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from "./admin.service";
import { Title } from "@angular/platform-browser";


@Component({
    selector: 'app-newslist',
    templateUrl: './newsEventList.component.html',
    styleUrls: ['./addBlogs.component.css'],
    providers: [AdminService]
})
export class NewsEventListComponent implements OnInit {
    newsEVentList: any;
    firstName: string;
    laststName: string;
    constructor(private _activeRoute: ActivatedRoute, private _router: Router, private _blogService: AdminService, private titleService: Title) { }

    ngOnInit() {
        this.titleService.setTitle('ATOM-News&Events');
        if (sessionStorage.getItem('username') === null) {
            this._router.navigate(['/adminLogin']);
        } else {
            this.firstName = sessionStorage.getItem('firstName');
            this.laststName = sessionStorage.getItem('lastName');
        }
        this._blogService.getNewsList()
            .subscribe((res) => {
                
                this.newsEVentList = res;
            }, (error) => {
                alert(error);
            });
    }
    deleteNews(id) {
        this._blogService.deleteNews(id)
            .subscribe((res) => {
                if(res){
                    alert('successfully deleted');
                    this.ngOnInit();
                }
            }, (error) => {
                alert(error);
            });
    }
    LogOut() {
        sessionStorage.removeItem('username');
        this._router.ngOnDestroy();
        this._router.navigate(['/adminLogin']);
    }
}