import { OnInit, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AdminService } from "./admin.service";
import { Title } from "@angular/platform-browser";


@Component({
    selector: 'app-newsdetails',
    templateUrl: './newsdetails.component.html',
    styleUrls: ['./newsdetails.component.css'],
    providers: [AdminService]
})
export class NewsDetailsComponent implements OnInit {
    blogdata: any;
    url: string;

    constructor(private _activeRoute: ActivatedRoute, private _blogService: AdminService, private titleService: Title) {}

    ngOnInit() {

        this.url = 'http://test.atomchemie.com/#/newsdetails/1';
        this.titleService.setTitle('ATOM - News Details');
        const id: string = this._activeRoute.snapshot.params['id'];
        this._blogService.getNewsEventById(id)
        .subscribe((res) => {
          // this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + res[0].BlogImage);
          this.blogdata = res;
        }, (error) => {
            alert(error);
        });
    }
}