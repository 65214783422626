import { OnInit, Component } from "@angular/core";
import { AtomeService } from "./atome.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
declare var $: any;

@Component({
    selector: 'app-food',
    templateUrl: './foodDairy.Component.html',
    styleUrls: ['./foodDairy.Component.css']
})
export class FoodDairyComponent implements OnInit {

    styleDairy: string = 'block';
    styleConfect: string = 'none';
    styleMeat: string = 'none';
    styleBaking: string = 'none';
    styleJuices: string = 'none';
    styleOils: string = 'none';
    styleSavory: string = 'none';
    styleHealth: string = 'none';
    dairyclass: string;
    confectclass: string;
    meatclass: string;
    bakingclass: string;
    juiceclass: string;
    oilclass: string;
    savouryclass: string;
    healthclass: string;
    modelfade: string = 'modal fade';
    showfade: string;
    ProductName: string;
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    Industry: string;
    constructor(private _atomeService: AtomeService, private titleService: Title, private _activeRoute: ActivatedRoute) { }
    ngOnInit() {
        // $(".overlay").show();
        this.titleService.setTitle('ATOM-Food');
        this.dairyclass = 'active';
        this.modelfade = 'modal fade';
        this.showfade = 'none';
        const prm: string = this._activeRoute.snapshot.params['prm'];

        if (prm === 'baking') {
            this.showBaking();
        } else if (prm === 'dairy'){
            this.showDairy();
        } else if (prm === 'meat-poultry-seafood') {
            this.showMeat();
        } else if (prm === 'margarines-fats-and-oils') {
            this.showOils();
        } else if (prm === 'confectionery') {
            this.showConfect();
        } else if (prm === 'savoury') {
            this.showSavoury();
        }
        else if (prm === 'juices-and-beverages') {
            this.showJuices();
        } else if (prm === 'health-functional-ingredients') {
            this.showHealth();
        }
    }

    showDairy() {
        this.styleDairy = 'block';
        this.styleConfect = 'none';
        this.styleMeat = 'none';
        this.styleBaking = 'none';
        this.styleJuices = 'none';
        this.styleOils = 'none';
        this.styleSavory = 'none';
        this.styleHealth = 'none';
        this.dairyclass = 'active';
        this.confectclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showConfect() {
        this.styleDairy = 'none';
        this.styleConfect = 'block';
        this.styleMeat = 'none';
        this.styleBaking = 'none';
        this.styleJuices = 'none';
        this.styleOils = 'none';
        this.styleSavory = 'none';
        this.styleHealth = 'none';
        this.dairyclass = '';
        this.confectclass = 'active';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showMeat() {
        this.styleDairy = 'none';
        this.styleConfect = 'none';
        this.styleMeat = 'block';
        this.styleBaking = 'none';
        this.styleJuices = 'none';
        this.styleOils = 'none';
        this.styleSavory = 'none';
        this.styleHealth = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = 'active';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showBaking() {
        this.styleDairy = 'none';
        this.styleConfect = 'none';
        this.styleMeat = 'none';
        this.styleBaking = 'block';
        this.styleJuices = 'none';
        this.styleOils = 'none';
        this.styleSavory = 'none';
        this.styleHealth = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = 'active';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showJuices() {
        this.styleDairy = 'none';
        this.styleConfect = 'none';
        this.styleMeat = 'none';
        this.styleBaking = 'none';
        this.styleJuices = 'block';
        this.styleOils = 'none';
        this.styleSavory = 'none';
        this.styleHealth = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = 'active';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showOils() {
        this.styleDairy = 'none';
        this.styleConfect = 'none';
        this.styleMeat = 'none';
        this.styleBaking = 'none';
        this.styleJuices = 'none';
        this.styleOils = 'block';
        this.styleSavory = 'none';
        this.styleHealth = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = 'active';
        this.savouryclass = '';
        this.healthclass = '';
    }

    showSavoury() {
        this.styleDairy = 'none';
        this.styleConfect = 'none';
        this.styleMeat = 'none';
        this.styleBaking = 'none';
        this.styleJuices = 'none';
        this.styleOils = 'none';
        this.styleSavory = 'block';
        this.styleHealth = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = 'active';
        this.healthclass = '';
    }

    showHealth() {
        this.styleDairy = 'none';
        this.styleConfect = 'none';
        this.styleMeat = 'none';
        this.styleBaking = 'none';
        this.styleJuices = 'none';
        this.styleOils = 'none';
        this.styleSavory = 'none';
        this.styleHealth = 'block';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = '';
        this.juiceclass = '';
        this.oilclass = '';
        this.savouryclass = '';
        this.healthclass = 'active';
    }

    openProductPostPopUp(value) {

        this.ProductName = value;
        this.modelfade = 'modal fade in';
        this.showfade = 'block';
    }

    closeClick() {

        this.modelfade = 'modal fade';
        this.showfade = 'none';
    }

    saveSegmentForm(value) {
        
        console.log(value);
        this._atomeService.saveSegmentInfo(value)
            .subscribe((res) => {
                if (res) {
                    alert('Form sent successfully!');
                    this.closeClick();
                } else {
                    alert('Form sending failed!');
                }
            }, (error) => {

            })
    }
}