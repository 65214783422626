import { OnInit, Component } from "@angular/core";

@Component({
    selector: 'app-mfooter',
    templateUrl: './footer.component.html'
})
export class MFooterComponent implements OnInit {

    ngOnInit() {

    }
}