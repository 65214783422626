import { OnInit, Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
    serachItem: string;
    imageObject: Array<object> = [{
        image: 'assets/Content/images/logos/paradise.jpg',
        thumbImage: 'assets/Content/images/logos/paradise.jpg',
        alt: 'paradise',
        href: 'https://www.paradisefruits.co.uk/',
        width: '180px',
        height: '90px'
    },{
        image: 'assets/Content/images/logos/pcc-logo.jpg',
        thumbImage: 'assets/Content/images/logos/pcc-logo.jpg',
        alt: 'pcc',
        href: 'https://www.pcc-exol.eu/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/prova-gb-logo.jpg',
        thumbImage: 'assets/Content/images/logos/prova-gb-logo.jpg',
        alt: 'prova',
        href: 'http://www.prova.fr/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/BASFo.jpg',
        thumbImage: 'assets/Content/images/logos/BASFo.jpg',
        alt: 'BASFo.jpg',
        href: 'https://nutrition.basf.com/global/en/human-nutrition.html',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/biospringerbaseQ.jpg',
        thumbImage: 'assets/Content/images/logos/biospringerbaseQ.jpg',
        alt: 'biospringerbaseQ',
        href: 'https://biospringer.com/en/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/brblogo.jpg',
        thumbImage: 'assets/Content/images/logos/brblogo.jpg',
        alt: 'brblogo',
        href: 'https://www.brb-international.com/lac/chemicals',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/capollogo.jpg',
        thumbImage: 'assets/Content/images/logos/capollogo.jpg',
        alt: 'capollogo',
        href: 'https://www.capol.de/en/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/ceamsalogo.jpg',
        thumbImage: 'assets/Content/images/logos/ceamsalogo.jpg',
        alt: 'ceamsalogo',
        href: 'https://www.ceamsa.com/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/globallogo.jpg',
        thumbImage: 'assets/Content/images/logos/globallogo.jpg',
        alt: 'globallogo',
        href: 'https://globaladsorbents.com/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/hexagonlogo.jpg',
        thumbImage: 'assets/Content/images/logos/hexagonlogo.jpg',
        alt: 'hexagonlogo',
        href: 'https://www.hexagonnutrition.com/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/mitsubhishilogo.jpg',
        thumbImage: 'assets/Content/images/logos/mitsubhishilogo.jpg',
        alt: 'mitsubhishilogo',
        href: 'https://www.mcls-ltd.com/en/',
        width: '180px',
        height: '90px'
    }, {
        image: 'assets/Content/images/logos/palsgaardlogo.jpg',
        thumbImage: 'assets/Content/images/logos/palsgaardlogo.jpg',
        alt: 'palsgaardlogo',
        href: 'https://www.palsgaard.com/en',
        width: '180px',
        height: '90px'
    }
    ];
    constructor(private _router: Router) { }
    ngOnInit() {

    }
    searchItem(value) {
        this._router.navigate(['search', value.q]);
    }
    setNavigate(event) {

        this._router.navigate([event.target.value]);
    }
    setHome() {
        window.open('/#/home');
    }
    fbook() {
        window.open('https://www.facebook.com/ATOM-282559109240788/?modal=admin_todo_tour/');
    }
    Instagram() {
        window.open('https://www.instagram.com/atomfzco/?hl=en/');
    }
    twitter() {
        window.open('https://twitter.com/Atom24291290');
    }
    linkedin() {
        window.open('https://www.linkedin.com/company/atom-fzco');
    }
}