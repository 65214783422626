import { OnInit, Component } from "@angular/core";
import { AtomeService } from "./atome.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";


@Component({
    selector: 'app-paints',
    templateUrl: './homeCareAndIndustrial.component.html',
    styleUrls: ['./homeCareAndIndustrial.component.css']
})
export class HomeCareAndIndustrialComponent implements OnInit {

    styleHardSurface: string = 'block';
    styleIndustrial: string = 'none';
    styleLaundry: string = 'none';
    styleDishWashing: string = 'none';
    dairyclass: string;
    confectclass: string;
    meatclass: string;
    bakingclass: string;
    modelfade: string = 'modal fade';
    showfade: string;
    ProductName: string;
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    Industry: string;
    
    constructor(private _atomeService: AtomeService, private titleService: Title, private _activeRoute: ActivatedRoute) { }
    ngOnInit() {

        this.titleService.setTitle('ATOM-HomeCareAndIndustrial');
        this.dairyclass = 'active';
        this.modelfade = 'modal fade';
        this.showfade = 'none';

        const prm: string = this._activeRoute.snapshot.params['prm'];

        if (prm === 'hard-surface-floor-care') {
            this.showHard();
        } else if (prm === 'industrial-cleaning') {
            this.showIndus();
        } else if (prm === 'laundry') {
            this.showLaundry();
        } else if (prm === 'dish-washing') {
            this.showDishWashing();
        }
    }

    openProductPostPopUp(value) {

        this.ProductName = value;
        this.modelfade = 'modal fade in';
        this.showfade = 'block';
    }

    closeClick() {

        this.modelfade = 'modal fade';
        this.showfade = 'none';
    }

    saveSegmentForm(value) {
       
        console.log(value);
        this._atomeService.saveSegmentInfo(value)
        .subscribe((res) => {
            if (res) {
                alert('Form sent successfully!');
                this.closeClick();
            } else {
                alert('Form sending failed!');
            }
        }, (error) => {
            
        })
    }

    showHard() {
        this.styleHardSurface = 'block';
        this.styleIndustrial = 'none';
        this.styleLaundry = 'none';
        this.styleDishWashing = 'none';
        this.dairyclass = 'active';
        this.confectclass = '';
        this.bakingclass = '';
        this.meatclass = '';
    }

    showIndus() {
        this.styleHardSurface = 'none';
        this.styleIndustrial = 'block';
        this.styleLaundry = 'none';
        this.styleDishWashing = 'none';
        this.dairyclass = '';
        this.confectclass = 'active';
        this.meatclass = '';
        this.bakingclass = '';
    }

    showLaundry() {
        this.styleHardSurface = 'none';
        this.styleIndustrial = 'none';
        this.styleLaundry = 'block';
        this.styleDishWashing = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = 'active';
        this.bakingclass = '';
    }

    showDishWashing() {
        this.styleHardSurface = 'none';
        this.styleIndustrial = 'none';
        this.styleLaundry = 'none';
        this.styleDishWashing = 'block';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = 'active';
    }
}