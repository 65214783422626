import { OnInit, Component } from "@angular/core";
import { AdminService } from "./admin.service";
import { DomSanitizer, SafeUrl, SafeResourceUrl, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.css'],
    providers: [AdminService]
})
export class NewsComponent implements OnInit {

    imagePath: SafeResourceUrl;
    newsList: any;
    p: number = 1;
    constructor(private _blogService: AdminService, private _sanitizer: DomSanitizer, private titleService: Title) {}
    
    ngOnInit() {
        this.titleService.setTitle('ATOM-News');
        this._blogService.getNewsList()
        .subscribe((res) => {
            
          // this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + res[0].BlogImage);
          this.newsList = res;
        }, (error) => {
            alert(error);
        });
    }

    
}
