import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class AtomeService {
    private baseUrl = environment.baseUrl;

    constructor(private _http: HttpClient) { }

    saveSegmentInfo(data){

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            withCredentials: true,
        };
        const obj = {
            'ProductName': data.ProductName,
            'Industry': data.Industry,
            'Name': data.Name,
            'Email': data.Email,
            'Phone': data.Phone,
            'Country': data.Country,
            'Subject': data.Subject,
            'Message': data.Message
        }

        return this._http.post(this.baseUrl + '/saveSegmentInfo', obj);
    }

    contactUs(data){

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            withCredentials: true,
        };
        const obj = {
            
            'Name': data.Name,
            'Email': data.Email,
            'Phone': data.Phone,
            'Country': data.Country,
            'Subject': data.Subject,
            'Message': data.Message
        }

        return this._http.post(this.baseUrl + '/contactUs', obj);
    }
}
