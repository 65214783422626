import { OnInit, Component, ElementRef } from "@angular/core";
import { AdminService } from "./admin.service";
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-addNewsEvents',
    templateUrl: './addNewsEvents.component.html',
    styleUrls: ['./addBlogs.component.css'],
    providers: [AdminService]
})
export class AddNewsEventsComponent implements OnInit {

    Title: string;
    Discription: string;
    firstName: string;
    laststName: string;
    userName: string;

    constructor(private _blogService: AdminService, private el: ElementRef, private _router: Router, private titleService: Title) { }

    ngOnInit() {

        this.titleService.setTitle('ATOM-News&Events');
        if (sessionStorage.getItem('username') === null) {
            this._router.navigate(['/adminLogin']);
        } else {
            this.firstName = sessionStorage.getItem('firstName');
            this.laststName = sessionStorage.getItem('lastName');
        }
    }

    public BlogImage: String = "";
    onFileChange($event): void {
        this.readThis($event.target);
    }

    readThis(inputValue: any): void {
        var file: File = inputValue.files[0];
        var myReader: FileReader = new FileReader();

        myReader.onloadend = this._handleReaderLoaded.bind(this);

        myReader.readAsDataURL(file);

    }
    _handleReaderLoaded(e) {
        debugger;
        console.log(e.currentTarget.result);
        this.BlogImage = e.currentTarget.result;
    }
    saveNewEvents(blog) {
        debugger;
        //  this.BlogImage = this.image;
        this._blogService.saveNewEvents(blog, this.BlogImage)
            .subscribe((res) => {
                if (res != null || res != undefined) {
                    if (res) {
                        alert('succefully created');
                        this.Title = '';
                        this.Discription = '';
                        this.BlogImage = '';
                    } else {
                        alert('some error please try agian');
                    }
                }
            }, (error) => {
                alert(error);
            })
    }

    LogOut() {
        sessionStorage.removeItem('username');
        this._router.ngOnDestroy();
        this._router.navigate(['/adminLogin']);
    }
}
