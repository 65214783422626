import { OnInit, Component } from "@angular/core";
import { AtomeService } from "./atome.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";


@Component({
    selector: 'app-construction',
    templateUrl: './construction.component.html',
    styleUrls: ['./construction.component.css']
})
export class ConstructionComponent implements OnInit {

    styleEpoxy: string = 'block';
    styleDry: string = 'none';
    styleConcrete: string = 'none';
    styleSealants: string = 'none';
    dairyclass: string;
    confectclass: string;
    meatclass: string;
    bakingclass: string;
    modelfade: string = 'modal fade';
    showfade: string;
    ProductName: string;
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    Industry: string;

    constructor(private _atomeService: AtomeService, private titleService: Title, private _activeRoute: ActivatedRoute) { }
    ngOnInit() {

        this.titleService.setTitle('ATOM-Construction');
        this.dairyclass = 'active';
        this.modelfade = 'modal fade';
        this.showfade = 'none';

        const prm: string = this._activeRoute.snapshot.params['prm'];

        if (prm === 'epoxy-pu-systems') {
            this.showEpoxy();
        } 
        else if (prm === 'dry-mortar-grouts') {
            this.showDry();
        } else if (prm === 'concrete-mixtures') {
            this.showConcrete();
        } else if (prm === 'sealants-waterproofing') {
            this.showSealants();
        }
    }

    openProductPostPopUp(value) {

        this.ProductName = value;
        this.modelfade = 'modal fade in';
        this.showfade = 'block';
    }

    closeClick() {

        this.modelfade = 'modal fade';
        this.showfade = 'none';
    }

    saveSegmentForm(value) {
       
        console.log(value);
        this._atomeService.saveSegmentInfo(value)
        .subscribe((res) => {
            if (res) {
                alert('Form sent successfully!');
                this.closeClick();
            } else {
                alert('Form sending failed!');
            }
        }, (error) => {
            
        })
    }
    
    showEpoxy() {
        this.styleEpoxy = 'block';
        this.styleDry = 'none';
        this.styleConcrete = 'none';
        this.styleSealants = 'none';
        this.dairyclass = 'active';
        this.confectclass = '';
        this.bakingclass = '';
        this.meatclass = '';
    }

    showDry() {
        this.styleEpoxy = 'none';
        this.styleDry = 'block';
        this.styleConcrete = 'none';
        this.styleSealants = 'none';
        this.dairyclass = '';
        this.confectclass = 'active';
        this.meatclass = '';
        this.bakingclass = '';
    }

    showConcrete() {
        this.styleEpoxy = 'none';
        this.styleDry = 'none';
        this.styleConcrete = 'block';
        this.styleSealants = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = 'active';
        this.bakingclass = '';
    }

    showSealants() {
        this.styleEpoxy = 'none';
        this.styleDry = 'none';
        this.styleConcrete = 'none';
        this.styleSealants = 'block';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = 'active';
    }
}