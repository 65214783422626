import { OnInit, Component, ElementRef } from "@angular/core";
import { AdminService } from "./admin.service";
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-mfooter',
    templateUrl: './addBlogs.component.html',
    styleUrls: ['./addBlogs.component.css'],
    providers: [AdminService]
})
export class AddBlogComponent implements OnInit {

    Title: string;
    Discription: string;
    firstName: string;
    laststName: string;
    userName: string;

    constructor(private _blogService: AdminService, private el: ElementRef, private _router: Router, private titleService: Title) { }

    ngOnInit() {
        
        this.titleService.setTitle('ATOM-Blog');
        if (sessionStorage.getItem('username') === null) {
            this._router.navigate(['/adminLogin']);
        } else {
            this.firstName = sessionStorage.getItem('firstName');
            this.laststName = sessionStorage.getItem('lastName');
        }
    }
    // onFileChange(event) {
    //     let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#image');


    //     this.BlogImage = inputEl.files.item(0).name;
    //     //   let files = event.target.files;
    //     // this.saveFiles(files);
    // }
    public base64textString: String = "";
    public BlogImage: String = "";

    // handleFileSelect(evt) {
    //     var files = evt.target.files;
    //     var file = files[0];

    //     if (files && file) {
    //         var reader = new FileReader();

    //         reader.onload = this._handleReaderLoaded.bind(this);

    //         reader.readAsBinaryString(file);
    //     }
    // }

    // _handleReaderLoaded(readerEvt) {
    //     var binaryString = readerEvt.target.result;
    //     this.base64textString = btoa(binaryString);
    //     console.log(btoa(binaryString));
    // }
    onFileChange($event): void {
        this.readThis($event.target);
    }

    readThis(inputValue: any): void {
        var file: File = inputValue.files[0];
        var myReader: FileReader = new FileReader();

        myReader.onloadend = this._handleReaderLoaded.bind(this);

        myReader.readAsDataURL(file);

    }
    _handleReaderLoaded(e) {
        debugger;
        console.log(e.currentTarget.result);
        this.BlogImage = e.currentTarget.result;
    }
    saveNewBlog(blog) {
        debugger;
        //  this.BlogImage = this.image;
        this._blogService.saveNewBlog(blog, this.BlogImage)
            .subscribe((res) => {
                if (res != null || res != undefined) {
                    if (res) {
                        alert('succefully create new student');
                        this.Title = '';
                        this.Discription = '';
                        this.base64textString = '';
                        // this.image = '';
                    } else {
                        alert('some error please try agian');
                    }
                }
            }, (error) => {
                alert(error);
            })
    }

    LogOut() {
        sessionStorage.removeItem('username');
        this._router.ngOnDestroy();
        this._router.navigate(['/adminLogin']);
    }
}
