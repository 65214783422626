import { OnInit, Component } from "@angular/core";
import { AtomeService } from "./atome.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";


@Component({
    selector: 'app-animafeed',
    templateUrl: './animalFeed.Component.html',
    styleUrls: ['./animalFeed.Component.css']
})
export class AnimalFeedComponent implements OnInit {

    stylePoultry: string = 'block';
    styleRuminants: string = 'none';
    Aquaculture: string = 'none';
    styleCompanion: string = 'none';
    dairyclass: string;
    confectclass: string;
    meatclass: string;
    bakingclass: string;
    modelfade: string = 'modal fade';
    showfade: string;
    ProductName: string;
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    Industry: string;
    
    constructor(private _atomeService: AtomeService, private titleService: Title, private _activeRoute: ActivatedRoute) { }
    ngOnInit() {
        
        this.titleService.setTitle('ATOM-AnimalFeed');
        this.dairyclass = 'active';
        this.modelfade = 'modal fade';
        this.showfade = 'none';

        const prm: string = this._activeRoute.snapshot.params['prm'];

        if (prm === 'poultry�broilers-layers') {
            this.showPoultry();
        } else if (prm === 'ruminants') {
            this.showRuminants();
        } else if (prm === 'aquaculture-equine') {
            this.showAquaculture();
        } else if (prm === 'companion-animals') {
            this.showCompanion();
        }
    }

    openProductPostPopUp(value) {

        this.ProductName = value;
        this.modelfade = 'modal fade in';
        this.showfade = 'block';
    }

    closeClick() {

        this.modelfade = 'modal fade';
        this.showfade = 'none';
    }

    saveSegmentForm(value) {
       
        console.log(value);
        this._atomeService.saveSegmentInfo(value)
        .subscribe((res) => {
            if (res) {
                alert('Form sent successfully!');
                this.closeClick();
            } else {
                alert('Form sending failed!');
            }
        }, (error) => {
            
        })
    }
    
    showPoultry() {
        this.stylePoultry = 'block';
        this.styleRuminants = 'none';
        this.Aquaculture = 'none';
        this.styleCompanion = 'none';
        this.dairyclass = 'active';
        this.confectclass = '';
        this.bakingclass = '';
        this.meatclass = '';
    }

    showRuminants() {
        this.stylePoultry = 'none';
        this.styleRuminants = 'block';
        this.Aquaculture = 'none';
        this.styleCompanion = 'none';
        this.dairyclass = '';
        this.confectclass = 'active';
        this.meatclass = '';
        this.bakingclass = '';
    }

    showAquaculture() {
        this.stylePoultry = 'none';
        this.styleRuminants = 'none';
        this.Aquaculture = 'block';
        this.styleCompanion = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = 'active';
        this.bakingclass = '';
    }

    showCompanion() {
        this.stylePoultry = 'none';
        this.styleRuminants = 'none';
        this.Aquaculture = 'none';
        this.styleCompanion = 'block';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = 'active';
    }

}