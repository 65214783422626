import { OnInit, Component, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { AtomeService } from "./atome.service";
import { Title } from "@angular/platform-browser";
declare var google: any;

@Component({
    selector: 'app-cotactUs',
    templateUrl: './contactUs.component.html',
    styleUrls: ['./contactUs.component.css']
})
export class ContactUsComponent implements OnInit, AfterViewInit {
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    // lat: number = 24.966898;
    // lng: number = 55.104114;

    // uluru: Object = { lat: 24.966898, lng: 55.104114 };
    uluru: Object = { lat: 24.986101, lng: 55.090884 };
    map: Object;
    marker: Object;
    zoom: number;
    @ViewChild('map') mapRef: ElementRef;
    constructor(private _atomeService: AtomeService, private titleService: Title) { }
    ngAfterViewInit() {
        //used setTimeout google map is delayed in loading, in stackBlitz
    
        setTimeout(() => {
          this.map = new google.maps.Map(this.mapRef.nativeElement, {
            zoom: 4,
            center: this.uluru
          });
          this.marker = new google.maps.Marker({
            position: this.uluru,
            map: this.map
          });
    
        }, 2000)
    
        //console.log(this.map.getZoom())
      }
    ngOnInit() {

        this.titleService.setTitle('ATOM-Contact Us');

    }

    contactUs(value) {

        console.log(value);
        this._atomeService.contactUs(value)
            .subscribe((res) => {
                if (res) {
                    alert('sent successfully!');
                    this.Name = '';
                    this.Email = '';
                    this.Phone = '';
                    this.Country = '';
                    this.Subject = '';
                    this.Message = '';
                } else {
                    alert('sending failed!');
                }
            }, (error) => {

            })
    }
}