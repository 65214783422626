import { OnInit, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AdminService } from "./admin.service";
import { Title } from "@angular/platform-browser";


@Component({
    selector: 'app-blogs',
    templateUrl: './blog.component.html',
    styleUrls: ['./blogList.component.css'],
    providers: [AdminService]
})
export class BlogComponent implements OnInit {
    blogdata: any;
    constructor(private _activeRoute: ActivatedRoute, private _blogService: AdminService, private titleService: Title) {}

    ngOnInit() {
        this.titleService.setTitle('ATOM-Blogs');
        const id: string = this._activeRoute.snapshot.params['id'];
        this._blogService.getBlogById(id)
        .subscribe((res) => {
          // this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + res[0].BlogImage);
          this.blogdata = res;
        }, (error) => {
            alert(error);
        });
    }
}