import { OnInit, Component } from "@angular/core";
declare var $: any;
@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./header.component.css']
})
export class SliderComponent implements OnInit {
    ngOnInit() {
        $(document).ready(function () {
            // $('.homemenu').delay(500).hide("slide", 2000);
            // $('.homemenu').delay(1000).show("slide", 2000);
            // $('div').animate({marginLeft:"0"},1500);
            // $('a').click(function () {
            //     $(this).hide();
            //     $('div').animate({ marginLeft: "0" }, 1500);
            // });
            // $(".image").css({ 'transform': 'rotate(-90deg)' });
            $('.box-inner').animate({ marginLeft: "0" }, 1500);
            $(".image").css({ 'transform': 'rotate(60deg)' });
            $(".image").css({ 'transform': 'rotate(-20deg)' });
        });
    }
}