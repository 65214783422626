import { OnInit, Component } from "@angular/core";
import { AdminService } from "./admin.service";
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-adminLogin',
    templateUrl: './adminLogin.component.html',
    styleUrls: ['./adminLogin.component.css'],
    providers: [AdminService]
})
export class AdminLoginComponent implements OnInit {

    adminLoginarr: any;
    email: string;
    password: string;
    constructor(private _blogService: AdminService, private _router: Router, private titleService: Title) { }

    ngOnInit() {
        this.titleService.setTitle('ATOM-Admin Login');
    }
    adminLogin(logniform) {
debugger;
        this._blogService.adminLogin(logniform)
            .subscribe((res) => {
                if (res !== null) {
                    sessionStorage.setItem('username', res.UserName);
                    sessionStorage.setItem('firstName', res.FirstName);
                    sessionStorage.setItem('lastName', res.LastName);
                    this._router.navigate(['/addNews']);
                }
            }, (error) => {

            })
    }
}