import { OnInit, Component } from "@angular/core";
import { AtomeService } from "./atome.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";


@Component({
    selector: 'app-paints',
    templateUrl: './paintsCoatingsInks.component.html',
    styleUrls: ['./paintsCoatingsInks.component.css']
})
export class PaintsCoatingsInksComponent implements OnInit {

    styleAutomotive: string = 'block';
    styleDecorative: string = 'none';
    styleWood: string = 'none';
    styleCoil: string = 'none';
    dairyclass: string;
    confectclass: string;
    meatclass: string;
    bakingclass: string;
    modelfade: string = 'modal fade';
    showfade: string;
    ProductName: string;
    Name: string;
    Email: string;
    Phone: string;
    Country: string;
    Subject: string;
    Message: string;
    Industry: string;
    
    constructor(private _atomeService: AtomeService, private titleService: Title, private _activeRoute: ActivatedRoute) { }
    ngOnInit() {
        this.titleService.setTitle('ATOM-PaintsCoatingsInks');
        this.dairyclass = 'active';
        this.modelfade = 'modal fade';
        this.showfade = 'none';

        const prm: string = this._activeRoute.snapshot.params['prm'];

        if (prm === 'automotive-marine') {
            this.showAutomotive();
        } else if (prm === 'decorative-coatings') {
            this.showDecorative();
        } else if (prm === 'wood-coatings') {
            this.showWater();
        } else if (prm === 'coil-industrial-coating') {
            this.showCoil();
        }
    }

    openProductPostPopUp(value) {

        this.ProductName = value;
        this.modelfade = 'modal fade in';
        this.showfade = 'block';
    }

    closeClick() {

        this.modelfade = 'modal fade';
        this.showfade = 'none';
    }

    saveSegmentForm(value) {
  
        console.log(value);
        this._atomeService.saveSegmentInfo(value)
        .subscribe((res) => {
            if (res) {
                alert('Form sent successfully!');
                this.closeClick();
            } else {
                alert('Form sending failed!');
            }
        }, (error) => {
            
        })
    }

    showAutomotive() {
        this.styleAutomotive = 'block';
        this.styleDecorative = 'none';
        this.styleWood = 'none';
        this.styleCoil = 'none';
        this.dairyclass = 'active';
        this.confectclass = '';
        this.bakingclass = '';
        this.meatclass = '';
    }

    showDecorative() {
        this.styleAutomotive = 'none';
        this.styleDecorative = 'block';
        this.styleWood = 'none';
        this.styleCoil = 'none';
        this.dairyclass = '';
        this.confectclass = 'active';
        this.meatclass = '';
        this.bakingclass = '';
    }

    showWater() {
        this.styleAutomotive = 'none';
        this.styleDecorative = 'none';
        this.styleWood = 'block';
        this.styleCoil = 'none';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = 'active';
        this.bakingclass = '';
    }

    showCoil() {
        this.styleAutomotive = 'none';
        this.styleDecorative = 'none';
        this.styleWood = 'none';
        this.styleCoil = 'block';
        this.dairyclass = '';
        this.confectclass = '';
        this.meatclass = '';
        this.bakingclass = 'active';
    }
}