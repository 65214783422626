import { OnInit, Component } from "@angular/core";
import { Title } from "@angular/platform-browser";


@Component({
    selector: 'app-aboutUs',
    templateUrl: './aboutUs.component.html',
    styleUrls: ['./aboutUs.component.css']
})
export class AboutUsComponent implements OnInit {

    constructor(private titleService: Title) { }
    ngOnInit() {
        this.titleService.setTitle('ATOM-About Us');

    }
}