import { Component, OnInit } from "@angular/core";

@Component({
selector: 'app-mheader',
templateUrl: './mheader.component.html',
styleUrls: ['./mheader.component.css']
})
export class MHeaderComponent implements OnInit {

    ngOnInit() {

    }

}