import { OnInit, Component } from "@angular/core";
import { AdminService } from "./admin.service";
import { DomSanitizer, SafeUrl, SafeResourceUrl, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-bloglist',
    templateUrl: './blogList.component.html',
    styleUrls: ['./blogList.component.css'],
    providers: [AdminService]
})
export class BlogListComponent implements OnInit {

    imagePath: SafeResourceUrl;
    blogList: any;
    constructor(private _blogService: AdminService, private _sanitizer: DomSanitizer, private titleService: Title) {}
    
    ngOnInit() {
        this.titleService.setTitle('ATOM-Blogs');
        this._blogService.getBlogList()
        .subscribe((res) => {
          // this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + res[0].BlogImage);
          this.blogList = res;
        }, (error) => {
            alert(error);
        });
    }

    
}
